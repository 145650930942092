import { Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import React from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DomainIcon from '@mui/icons-material/Domain';
import { Link } from "react-router-dom";

export interface LeftMenuContextProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const LeftMenuContext = React.createContext<LeftMenuContextProps | null>(null);

function LeftMenu() {
  const leftMenuCtx = React.useContext(LeftMenuContext);

  return (
    <Drawer
      anchor="left"
      open={leftMenuCtx?.open}
      onClose={() => {leftMenuCtx?.setOpen(false)}}
    >
      <Toolbar style={{
        minWidth: 270,
      }}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="close menu"
          onClick={() => {leftMenuCtx?.setOpen(false)}}
          sx={{ mr: 2 }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <span style={{
          flexGrow: 1,
        }} />
      </Toolbar>
      <List
        sx={{ width: '100%', maxWidth: 270, bgcolor: 'background.paper' }}
        component="nav"
      >
        <Link to="/qrs">
          <ListItemButton>
            <ListItemIcon>
              <QrCodeIcon />
            </ListItemIcon>
            <ListItemText primary="QRs" />
          </ListItemButton>
        </Link>
        <Link to="/domains">
          <ListItemButton>
            <ListItemIcon>
              <DomainIcon />
            </ListItemIcon>
            <ListItemText primary="Domains" />
          </ListItemButton>
        </Link>
      </List>
    </Drawer>
  );
}

export default LeftMenu;
