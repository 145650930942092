import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { User, WebStorageStateStore } from 'oidc-client-ts';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';

const oidcConfig: AuthProviderProps = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY ?? "https://keycloak.capi.cloud/realms/Capi",
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID ?? "dynamic-qr",
  redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI ?? "http://localhost:3000",
  onSigninCallback: (_user: User | void): void => {
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname
    )
  },
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  monitorSession: true,
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AuthProvider {...oidcConfig}>
        <CssBaseline />
        <App />
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
