import React from "react";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Domain from "../classes/domain";

interface Column {
  id: 'domain' | 'actions';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'domain', label: 'Domain', minWidth: 100 },
  { id: 'actions', label: '', minWidth: 170 },
];

export interface DomainTableProps {
  domains: Domain[];
  onEdit?: (domain: Domain) => void;
  onDelete?: (domain: Domain) => void;
};

const DomainTable = (props: DomainTableProps) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.domains
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.uuid}>
                    <TableCell>
                      <a
                        href={ row.domain }
                        target="_blank"
                        rel="noreferrer"
                      >
                        { row.domain }
                      </a>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton aria-label="edit" onClick={() => {
                        if (props.onEdit) props.onEdit(row);
                      }}>
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete" onClick={() => {
                        if (props.onDelete) props.onDelete(row);
                      }}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.domains.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default DomainTable;
