import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, FormControl, InputLabel, Select, MenuItem, Box, SelectChangeEvent } from "@mui/material";
import QR from "../classes/qr";
import Domain from "../classes/domain";
import { gql, useQuery } from "@apollo/client";

export interface EditQrDialogProps {
  qr?: QR;
  open: boolean;
  onClose: (href?: string, domainUUID?: string) => void;
}

function EditQrDialog(props: EditQrDialogProps) {
  const [ domains, setDomains ] = React.useState<Domain[]>();
  const [ selectedDomain, setSelectedDomain ] = React.useState<Domain | undefined>(props.qr?.domain);

  const { loading, error, data } = useQuery(gql(`
    query GetDomains {
      domain {
        domain
        uuid
      }
    }
  `));

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedDomain(domains?.find(domain => domain.uuid === event.target.value));
  };

  React.useEffect(() => {
    if (data) {
      setDomains(data.domain);
      setSelectedDomain(data.domain.find((domain: Domain) => domain.uuid === props.qr?.domain?.uuid));
    }
  }, [ data, props.qr?.domain?.uuid ]);

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={props.open}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onClose={() => {props.onClose()}}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            event.stopPropagation();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            props.onClose(formJson.href, selectedDomain?.uuid);
          },
        }}
      >
        <DialogTitle>{ props.qr ? 'Edit QR' : 'Add QR' }</DialogTitle>
        <DialogContent>
          <Box style={{
            margin: '10px 0',
          }}>
            <TextField
              autoFocus
              required
              margin="dense"
              id="href"
              name="href"
              label="Target"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={props.qr?.href}
            />
          </Box>
          <Box style={{
            margin: '10px 0',
          }}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="domain-select-label">
                Domain
              </InputLabel>
              <Select
                labelId="domain-select-label"
                id="domain-select"
                label="Domain"
                value={selectedDomain?.uuid ?? 'None'}
                onChange={handleChange}
              >
                <MenuItem value="None">
                  <em>None</em>
                </MenuItem>
                { domains?.map(domain => (
                  <MenuItem key={domain.uuid} value={domain.uuid}>{domain.domain}</MenuItem>
                )) }
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {props.onClose()}}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default EditQrDialog;
