import { gql, useMutation, useQuery } from "@apollo/client";
import React from "react";
import Loading from "../components/Loading";
import Domain from "../classes/domain";
import DomainTable from "../components/DomainTable";
import { Button } from "@mui/material";
import EditDomainDialog from "../dialogs/EditDomainDialog";

const Domains = () => {
  const [ domains, setDomains ] = React.useState<Domain[]>([]);

  const [ editDomain, setEditDomain ] = React.useState<Domain | undefined>(undefined);
  const [ editDialogOpen, setEditDialogOpen ] = React.useState<boolean>(false);

  const { loading, error, data } = useQuery(gql(`
    query GetDomains {
      domain {
        domain
        uuid
      }
    }
  `));

  const [ addDomain ] = useMutation(gql(`
    mutation AddDomain($domain: String!) {
      insert_domain_one(object: { domain: $domain }) {
        uuid
      }
    }
  `), {
    refetchQueries: [
      'GetDomains',
    ],
  });

  const [ updateDomain ] = useMutation(gql(`
    mutation UpdateDomain($uuid: uuid!, $domain: String!) {
      update_domain_by_pk(pk_columns: { uuid: $uuid }, _set: { domain: $domain }) {
        uuid
      }
    }
  `), {
    refetchQueries: [
      'GetDomains',
    ],
  });

  const [ deleteDomain ] = useMutation(gql(`
    mutation DeleteDomain($uuid: uuid!) {
      delete_domain_by_pk(uuid: $uuid) {
        uuid
      }
    }
  `), {
    refetchQueries: [
      'GetDomains',
    ],
  });

  const handleAdd = () => {
    setEditDomain(undefined);
    setEditDialogOpen(true);
  };

  const handleEdit = (domain: Domain) => {
    setEditDomain(domain);
    setEditDialogOpen(true);
  }

  React.useEffect(() => {
    if (data) {
      setDomains(data.domain);
    }
  }, [data]);

  if (loading) return <Loading open />;

  return (
    <React.Fragment>
      <div style={{
        marginBottom: '10px',
        textAlign: 'right',
      }}>
        <Button variant="contained" onClick={handleAdd}>
          Add
        </Button>
      </div>
      <DomainTable domains={domains} onEdit={handleEdit} onDelete={(domain) => {
        deleteDomain({
          variables: {
            uuid: domain.uuid,
          }
        });
      }} />
      <EditDomainDialog
        open={editDialogOpen}
        domain={editDomain}
        onClose={domain => {
          if (domain) {
            if (editDomain) {
              updateDomain({
                variables: {
                  uuid: editDomain.uuid,
                  domain,
                }
              });
            } else {
              addDomain({
                variables: {
                  domain,
                }
              });
            }
          }
          setEditDialogOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export default Domains;
