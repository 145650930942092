import React from 'react';
import { Outlet } from 'react-router-dom';
import TopBar from '../components/TopBar';
import LeftMenu from '../components/LeftMenu';
import { Container, CssBaseline } from '@mui/material';

const Layout = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <TopBar />
      <Container maxWidth="lg" style={{
        paddingTop: '36px',
      }}>
        <Outlet />
      </Container>
      <LeftMenu />
    </React.Fragment>
  );
};

export default Layout;
