import { gql, useMutation, useQuery } from "@apollo/client";
import React from "react";
import Loading from "../components/Loading";
import QR from "../classes/qr";
import QrTable from "../components/QrTable";
import { Button } from "@mui/material";
import EditQrDialog from "../dialogs/EditQrDialog";
import ShowQrDialog from "../dialogs/ShowQrDialog";

const Dashboard = () => {
  const [ QRs, setQRs ] = React.useState<QR[]>([]);

  const [ editQr, setEditQr ] = React.useState<QR | undefined>(undefined);
  const [ editDialogOpen, setEditDialogOpen ] = React.useState<boolean>(false);
  const [ showDialogOpen, setShowDialogOpen ] = React.useState<boolean>(false);

  const { loading, error, data } = useQuery(gql(`
    query GetQrs {
      qr {
        href
        uuid
        domain {
          domain
          uuid
        }
      }
    }
  `));

  const [ addQr ] = useMutation(gql(`
    mutation AddQr($href: String!, $domainUUID: uuid) {
      insert_qr_one(object: { href: $href, domain_uuid: $domainUUID }) {
        href
        uuid
      }
    }
  `), {
    refetchQueries: [
      'GetQrs',
    ],
  });

  const [ updateQr ] = useMutation(gql(`
    mutation UpdateQr($uuid: uuid!, $href: String!, $domainUUID: uuid) {
      update_qr_by_pk(pk_columns: { uuid: $uuid }, _set: { href: $href, domain_uuid: $domainUUID }) {
        href
        uuid
      }
    }
  `), {
    refetchQueries: [
      'GetQrs',
    ],
  });

  const [ deleteQr ] = useMutation(gql(`
    mutation DeleteQr($uuid: uuid!) {
      delete_qr_by_pk(uuid: $uuid) {
        href
        uuid
      }
    }
  `), {
    refetchQueries: [
      'GetQrs',
    ],
  });

  const handleAdd = () => {
    setEditQr(undefined);
    setEditDialogOpen(true);
  };

  const handleEdit = (qr: QR) => {
    setEditQr(qr);
    setEditDialogOpen(true);
  }

  const handleShow = (qr: QR) => {
    setEditQr(qr);
    setShowDialogOpen(true);
  }

  React.useEffect(() => {
    if (data) {
      setQRs(data.qr);
    }
  }, [data]);

  if (loading) return <Loading open />;

  return (
    <React.Fragment>
      <div style={{
        marginBottom: '10px',
        textAlign: 'right',
      }}>
        <Button variant="contained" onClick={handleAdd}>
          Add
        </Button>
      </div>
      <QrTable QRs={QRs} onEdit={handleEdit} onDelete={(qr) => {
        deleteQr({
          variables: {
            uuid: qr.uuid,
          }
        });
      }} onShow={handleShow} />
      <ShowQrDialog
        open={showDialogOpen}
        qr={editQr}
        onClose={() => {
          setShowDialogOpen(false);
        }}
      />
      <EditQrDialog
        open={editDialogOpen}
        qr={editQr}
        onClose={(href, domainUUID) => {
          if (href) {
            if (editQr) {
              updateQr({
                variables: {
                  uuid: editQr.uuid,
                  href,
                  domainUUID,
                }
              });
            } else {
              addQr({
                variables: {
                  href,
                  domainUUID,
                }
              });
            }
          }
          setEditDialogOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export default Dashboard;
