import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Box } from "@mui/material";
import Domain from "../classes/domain";

export interface EditDomainDialogProps {
  domain?: Domain;
  open: boolean;
  onClose: (domain?: string) => void;
}

function EditDomainDialog(props: EditDomainDialogProps) {
  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={props.open}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onClose={() => {props.onClose()}}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            event.stopPropagation();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            props.onClose(formJson.domain);
          },
        }}
      >
        <DialogTitle>{ props.domain ? 'Edit Domain' : 'Add Domain' }</DialogTitle>
        <DialogContent>
          <Box style={{
            margin: '10px 0',
          }}>
            <TextField
              autoFocus
              required
              margin="dense"
              id="domain"
              name="domain"
              label="Domain"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={props.domain?.domain}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {props.onClose()}}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default EditDomainDialog;
