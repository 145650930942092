import { AppBar, Box, IconButton, Toolbar, Typography, styled } from "@mui/material";
import React from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { LeftMenuContext } from "./LeftMenu";

function TopBar() {
  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

  const leftMenuCtx = React.useContext(LeftMenuContext);

  return (
    <React.Fragment>
      <Offset />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              onClick={() => {leftMenuCtx?.setOpen(!leftMenuCtx?.open)}}
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              noWrap
              component="div"
              sx={{ flexGrow: 1, alignSelf: 'flex-end' }}
            ></Typography>
          </Toolbar>
        </AppBar>
      </Box>
    </React.Fragment>
  );
}

export default TopBar;
