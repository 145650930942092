import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import QR from "../classes/qr";

export interface ShowQrDialogProps {
  qr?: QR;
  open: boolean;
  onClose: () => void;
}

function ShowQrDialog(props: ShowQrDialogProps) {
  const formatURL = (url: string) => {
    const res = url.replace(/([^:]\/)\/+/g, "$1");
    // prepend https:// if neither http:// nor https:// are present
    if (!res.match(/^(http|https):\/\//)) {
      return 'https://' + res;
    }
    return res;
  }

  const handleDownload = () => {
    if (!props.qr) return;

    const a = document.createElement('a');
    a.href = 'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=' +
      formatURL((props.qr.domain?.domain ?? 'https://qr.capi.cloud') + '/qr/' + props.qr.uuid);
    a.download = 'qr.png';
    a.target = '_blank';
    a.rel = 'noreferrer';
    a.click();
    a.remove();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={props.open}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onClose={() => {props.onClose()}}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            event.stopPropagation();
            props.onClose();
          },
        }}
      >
        <DialogTitle>Show QR</DialogTitle>
        <DialogContent>
          { props.qr && 
            <img
              style={{
                display: 'block',
                margin: 'auto',
                maxWidth: '100%',
              }}
              src={
                'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=' +
                  formatURL((props.qr.domain?.domain ?? 'https://qr.capi.cloud') + '/qr/' + props.qr.uuid)
              }
              alt="QR"
            /> }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDownload}>Download</Button>
          <Button type="submit">OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ShowQrDialog;
