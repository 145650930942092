import React from "react";
import QR from "../classes/qr";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface Column {
  id: 'uuid' | 'href' | 'actions';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'uuid', label: 'Link', minWidth: 170 },
  { id: 'href', label: 'Target', minWidth: 100 },
  { id: 'actions', label: '', minWidth: 170 },
];

export interface QrTableProps {
  QRs: QR[];
  onEdit?: (qr: QR) => void;
  onDelete?: (qr: QR) => void;
  onShow?: (qr: QR) => void;
};

const QrTable = (props: QrTableProps) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const formatURL = (url: string) => {
    const res = url.replace(/([^:]\/)\/+/g, "$1");
    // prepend https:// if neither http:// nor https:// are present
    if (!res.match(/^(http|https):\/\//)) {
      return 'https://' + res;
    }
    return res;
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.QRs
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.uuid}>
                    <TableCell>
                      <a
                        href={ formatURL((row.domain?.domain ?? 'https://qr.capi.cloud') + '/qr/' + row.uuid) }
                        target="_blank"
                        rel="noreferrer"
                      >
                        { formatURL((row.domain?.domain ?? 'https://qr.capi.cloud') + '/qr/' + row.uuid) }
                      </a>
                    </TableCell>
                    <TableCell>
                      <a
                        href={ row.href }
                        target="_blank"
                        rel="noreferrer"
                      >{ row.href }</a>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton aria-label="view" onClick={() => {
                        if (props.onShow) props.onShow(row);
                      }}>
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton aria-label="edit" onClick={() => {
                        if (props.onEdit) props.onEdit(row);
                      }}>
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete" onClick={() => {
                        if (props.onDelete) props.onDelete(row);
                      }}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.QRs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default QrTable;
