import React from 'react';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './layouts/Layout';
import Dashboard from './pages/Dashboard';
import { LeftMenuContext, LeftMenuContextProps } from './components/LeftMenu';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import Domains from './pages/Domains';

function App() {
  const [ leftMenuOpen, setLeftMenuOpen ] = React.useState<boolean>(false);
  const leftMenuCtxValue: LeftMenuContextProps = { open: leftMenuOpen, setOpen: setLeftMenuOpen };

  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = React.useState(false);

  const httpLink = createHttpLink({
    uri: 'https://qr.capi.cloud/v1/graphql',
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = auth.user?.access_token;
    // return the headers to the context so httpLink can read them
    const h = {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
    return h;
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  // automatically sign-in
  React.useEffect(() => {
    if (!hasAuthParams() &&
      !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  return (
    <ApolloProvider client={client}>
      <LeftMenuContext.Provider value={leftMenuCtxValue}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Dashboard />} />
              <Route path='qrs' element={<Dashboard />} />
              <Route path='domains' element={<Domains />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </LeftMenuContext.Provider>
    </ApolloProvider>
  );
}

export default App;
