import { Backdrop, Button, CircularProgress } from "@mui/material";
import React from "react";

export interface LoadingProps {
  progress?: number;
  open: boolean;
  height?: number | string;
  width?: number | string;
  top?: number | string;
  left?: number | string;
  onCancel?: () => void;
}

function Loading(props: LoadingProps) {
  return (
    <React.Fragment>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }}
        open={props.open}
        style={{
          height: props.height || '100%',
          width: props.width || '100%',
          top: props.top || '0',
          left: props.left || '0',
          flexFlow: 'column',
        }}
      >
        <CircularProgress size={70} 
          variant={props.progress ? 'determinate' : 'indeterminate'}
          value={props.progress ? props.progress * 100 : undefined}
          color='secondary' />
        { props.onCancel &&
          <React.Fragment>
            <br />
            <Button
              onClick={props.onCancel}
              color="secondary"
              size="medium">
              Cancel
            </Button>
          </React.Fragment>
        }
      </Backdrop>
    </React.Fragment>
  );
}

export default Loading;
